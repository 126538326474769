import { BaseClass } from './base'
import defaultConfig from '@/config/default.config'
export class UserClass extends BaseClass {
  modelRef = {
    uname: '',
    pwd: '',
    password_confirm: '',
    name: '',
    mobile: '',
    roleId: '',
    sex: 0,
    age: 0,
    avatar: '',
    address: ''
  }

  rulesRef = {
    uname: [
      {
        required: true,
        message: '请输入用户名'
      }
    ],
    pwd: [
      {
        required: true,
        message: '请输入密码'
      }
    ],
    name: [
      {
        required: true,
        message: '请输入姓名'
      }
    ]
  }

  // 平台登录
  login (data) {
    return this.post(this.account + '/ManageLogin', data)
  }

  // 酒店登录
  slogin (data) {
    return this.post(this.account + '/HotelLogin', data)
  }

  // 客户登录
  clogin (data) {
    return this.post(this.account + '/CustomerLogin', data)
  }

  menus () {
    return this.get(this.menu + '/GetUserRoleMenus')
  }

  userInfo () {
    return this.get(this.account + '/UserCache')
  }

  logout () {
    return this.get(this.account + '/Logout')
  }

  psw (data) {
    return this.put(this.account + '/ChangePsw', data)
  }

  gets (params) {
    return this.get(this.user, params)
  }

  create (data) {
    return this.post(this.user, data)
  }

  update (data) {
    return this.put(this.user, data)
  }

  del (id) {
    return this.delete(this.user + `/${id}`)
  }

  state (params) {
    return this.get(this.user + '/AllowLogin', params)
  }

  bind (userid, storeId) {
    return this.post(this.user + '/BindStore', { userid, storeId })
  }

  infos () {
    return this.get(this.user + '/GetCustomerInfo', { storeId: defaultConfig.store_id })
  }

  waiters () {
    return this.get(this.user + '/GetWaiters')
  }

  platformUsers () {
    return this.get(this.user + '/GetAllPlatformUsers')
  }

  handlersUsers () {
    return this.get(this.user + '/GetAllHandlersUsers')
  }

  getManagersAndCurrUser () {
    return this.get(this.user + '/GetManagersAndCurrUser')
  }
}

export class CustomerClass extends BaseClass {
  modelRef = {
    name: '',
    mobile: '',
    sex: 1,
    managerId: ''
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入用户名'
      }
    ],
    mobile: [
      {
        required: true,
        message: '请输入手机号'
      }
    ]
  }

  gets (params) {
    return this.get(this.customer, params)
  }

  mobile (mobile) {
    return this.get(this.customer + '/GetByMobile', { mobile })
  }

  getCoupons (id) {
    return this.get(this.customer + `/CustomerCoupons/${id}`)
  }

  create (data) {
    return this.post(this.customer, data)
  }

  update (data) {
    return this.put(this.customer, data)
  }
}

export class SmsClass extends BaseClass {
  sendOne (content, mobile) {
    return this.get(this.sms + '/SendSms', { content, mobile })
  }

  sendMany (content) {
    return this.get(this.sms + '/BatchSendSms', { content })
  }
}
