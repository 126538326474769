import { UserLayout, BasicLayout, RouteView, MobileLayout, CounterLayout, CounterLayout2 } from '@/layouts'
// import defaultConfig from './default.config'

// 平台路由
const asyncRouterMap = [
  {
    path: '/manage',
    name: 'manage',
    component: BasicLayout,
    redirect: '/manage/dashboard',
    children: [
      // 数据
      {
        path: '/manage/dashboard',
        name: 'manage-dashboard',
        redirect: '/manage/dashboard/analysis',
        component: RouteView,
        meta: { title: '数据', keepAlive: true, icon: 'icon-shujuzhanshi2', permission: ['manage-dashboard-analysis', 'manage-dashboard-vacate'] },
        children: [
          {
            path: '/manage/dashboard/analysis',
            name: 'manage-dashboard-analysis',
            component: () => import('@/views/hg-platform/dashboard/Analysis'),
            meta: { title: '数据统计', keepAlive: true, permission: ['manage-dashboard-analysis'] }
          }
        ]
      },

      // 酒店
      {
        path: '/manage/store',
        name: 'manage-store',
        redirect: '/manage/store/list',
        component: RouteView,
        meta: { title: '店铺', keepAlive: true, icon: 'icon-xuexiao_xuexiaoxinxi', permission: ['manage-store-list'] },
        children: [
          {
            path: '/manage/store/list',
            name: 'manage-store-list',
            component: () => import('@/views/hg-platform/store/List'),
            meta: { title: '店铺列表', keepAlive: true, permission: ['manage-store-list'] }
          }
        ]
      },

      // 商品
      {
        path: '/manage/goods',
        name: 'manage-goods',
        redirect: '/manage/goods/list',
        component: RouteView,
        meta: { title: '商品', keepAlive: true, icon: 'icon-shangpin', permission: ['manage-goods-list', 'manage-goods-category'] },
        children: [
          {
            path: '/manage/goods/list',
            name: 'manage-goods-list',
            component: () => import('@/views/hg-platform/goods/List'),
            meta: { title: '商品列表', keepAlive: true, permission: ['manage-goods-list'] }
          },
          {
            path: '/manage/goods/category',
            name: 'manage-goods-category',
            component: () => import('@/views/hg-platform/goods/Category'),
            meta: { title: '商品分类', keepAlive: true, permission: ['manage-goods-category'] }
          }
        ]
      },

      // 用户
      {
        path: '/manage/user',
        name: 'manage-user',
        redirect: '/manage/user/list',
        component: RouteView,
        meta: {
          title: '用户',
          keepAlive: true,
          icon: 'icon-yonghu1',
          permission: [
            'manage-user-list',
            'manage-user-hotel',
            'manage-user-shareholder',
            'manage-user-counter',
            'manage-user-handlers',
            'manage-user-chef',
            'manage-user-finance',
            'manage-user-warehouse',
            'manage-user-customer'
          ]
        },
        children: [
          {
            path: '/manage/user/list',
            name: 'manage-user-list',
            component: () => import('@/views/hg-platform/user/List'),
            meta: { title: '平台管理员', keepAlive: true, permission: ['manage-user-list'] }
          },
          {
            path: '/manage/user/hotel',
            name: 'manage-user-hotel',
            component: () => import('@/views/hg-platform/user/Hotel'),
            meta: { title: '酒店管理员', keepAlive: true, permission: ['manage-user-hotel'] }
          },
          {
            path: '/manage/user/shareholder',
            name: 'manage-user-shareholder',
            component: () => import('@/views/hg-platform/user/Shareholder'),
            meta: { title: '股东', keepAlive: true, permission: ['manage-user-shareholder'] }
          },
          {
            path: '/manage/user/finance',
            name: 'manage-user-finance',
            component: () => import('@/views/hg-platform/user/Finance'),
            meta: { title: '财务', keepAlive: true, permission: ['manage-user-finance'] }
          },
          {
            path: '/manage/user/warehouse',
            name: 'manage-user-warehouse',
            component: () => import('@/views/hg-platform/user/Warehouse'),
            meta: { title: '库管', keepAlive: true, permission: ['manage-user-warehouse'] }
          },
          {
            path: '/manage/user/chef',
            name: 'manage-user-chef',
            component: () => import('@/views/hg-platform/user/Chef'),
            meta: { title: '厨房', keepAlive: true, permission: ['manage-user-chef'] }
          },
          {
            path: '/manage/user/counter',
            name: 'manage-user-counter',
            component: () => import('@/views/hg-platform/user/Counter'),
            meta: { title: '吧台', keepAlive: true, permission: ['manage-user-counter'] }
          },
          {
            path: '/manage/user/handlers',
            name: 'manage-user-handlers',
            component: () => import('@/views/hg-platform/user/Handlers'),
            meta: { title: '客户经理', keepAlive: true, permission: ['manage-user-handlers'] }
          },
          {
            path: '/manage/user/customer',
            name: 'manage-user-customer',
            component: () => import('@/views/hg-platform/user/Customer'),
            meta: { title: '客户', keepAlive: true, permission: ['manage-user-customer'] }
          }
        ]
      },
      // 权限
      {
        path: '/manage/auth',
        name: 'manage-auth',
        redirect: '/manage/auth/roles',
        component: RouteView,
        meta: { title: '权限', keepAlive: true, icon: 'icon-quanxian', permission: ['manage-auth-roles', 'manage-auth-rules'] },
        children: [
          {
            path: '/manage/auth/roles',
            name: 'manage-auth-roles',
            component: () => import('@/views/hg-platform/auth/Roles'),
            meta: { title: '角色列表', keepAlive: true, permission: ['manage-auth-roles'] }
          },
          {
            path: '/manage/auth/rules',
            name: 'manage-auth-rules',
            component: () => import('@/views/hg-platform/auth/Rules'),
            meta: { title: '权限列表', keepAlive: true, permission: ['manage-auth-rules'] }
          }
        ]
      }
    ]
  }
]

// 酒店管理员路由
const hotelRouterMap = [
  {
    path: '/hotel',
    name: 'hotel',
    component: BasicLayout,
    redirect: '/hotel/dashboard',
    children: [
      // 数据
      {
        path: '/hotel/dashboard',
        name: 'hotel-dashboard',
        redirect: '/hotel/dashboard/analysis',
        component: RouteView,
        meta: { title: '数据', keepAlive: true, icon: 'icon-shujuzhanshi2', permission: ['hotel-dashboard-analysis', 'hotel-dashboard-vacate'] },
        children: [
          {
            path: '/hotel/dashboard/analysis',
            name: 'hotel-dashboard-analysis',
            component: () => import('@/views/hg-hotel/dashboard/Analysis'),
            meta: { title: '数据统计', keepAlive: true, permission: ['hotel-dashboard-analysis'] }
          },
          {
            path: '/hotel/dashboard/combo',
            name: 'hotel-dashboard-combo',
            component: () => import('@/views/hg-hotel/dashboard/Combo'),
            meta: { title: '套餐统计', keepAlive: true, permission: ['hotel-dashboard-combo'] }
          },
          {
            path: '/hotel/dashboard/dish',
            name: 'hotel-dashboard-dish',
            component: () => import('@/views/hg-hotel/dashboard/DishStat'),
            meta: { title: '菜品清单统计', keepAlive: true, permission: ['hotel-dashboard-dish'] }
          },
          {
            path: '/hotel/dashboard/dish-sum',
            name: 'hotel-dashboard-dish-sum',
            component: () => import('@/views/hg-hotel/dashboard/DishSumStat'),
            meta: { title: '菜品合计统计', keepAlive: true, permission: ['hotel-dashboard-dish-sum'] }
          },
          {
            path: '/hotel/dashboard/dish-goods',
            name: 'hotel-dashboard-dish-goods',
            component: () => import('@/views/hg-hotel/dashboard/DishGoodsStat'),
            meta: { title: '主辅料统计', keepAlive: true, permission: ['hotel-dashboard-dish-goods'] }
          }
        ]
      },

      // 商品
      {
        path: '/hotel/goods',
        name: 'hotel-goods',
        redirect: '/hotel/goods/list',
        component: RouteView,
        meta: { title: '商品', keepAlive: true, icon: 'icon-shangpin', permission: ['hotel-goods-list', 'hotel-goods-category'] },
        children: [
          {
            path: '/hotel/goods/list',
            name: 'hotel-goods-list',
            component: () => import('@/views/hg-hotel/goods/List'),
            meta: { title: '商品列表', keepAlive: true, permission: ['hotel-goods-list'] }
          },
          {
            path: '/hotel/goods/category',
            name: 'hotel-goods-category',
            component: () => import('@/views/hg-hotel/goods/Category'),
            meta: { title: '商品分类', keepAlive: true, permission: ['hotel-goods-category'] }
          }
        ]
      },

      // 菜品
      {
        path: '/hotel/dish',
        name: 'hotel-dish',
        redirect: '/hotel/dish/list',
        component: RouteView,
        meta: {
          title: '菜品',
          keepAlive: true,
          icon: 'icon-meishi1',
          permission: [
            'hotel-dish-list',
            'hotel-dish-form',
            'hotel-dish-form-edit',
            'hotel-combo-list',
            'hotel-combo-form',
            'hotel-combo-form-edit',
            'hotel-dish-must',
            'hotel-dish-type'
          ]
        },
        children: [
          {
            path: '/hotel/dish/index',
            name: 'hotel-dish-index',
            redirect: '/hotel/dish/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '菜品管理', keepAlive: true },
            children: [
              {
                path: '/hotel/dish/list',
                name: 'hotel-dish-list',
                component: () => import('@/views/hg-hotel/dish/List'),
                meta: { title: '菜品列表', keepAlive: true, permission: ['hotel-dish-list'] }
              },
              {
                path: '/hotel/dish/form',
                name: 'hotel-dish-form',
                component: () => import('@/views/hg-hotel/dish/Form'),
                meta: { title: '添加菜品', keepAlive: true, permission: ['hotel-dish-form'] }
              },
              {
                path: '/hotel/dish/form/:id',
                name: 'hotel-dish-form-edit',
                component: () => import('@/views/hg-hotel/dish/Form'),
                meta: { title: '编辑菜品', keepAlive: true, permission: ['hotel-dish-form-edit'] }
              }
            ]
          },
          {
            path: '/hotel/dish/must',
            name: 'hotel-dish-must',
            component: () => import('@/views/hg-hotel/dish/Must'),
            meta: { title: '商转菜品', keepAlive: true, permission: ['hotel-dish-must'] }
          },
          {
            path: '/hotel/dish/type',
            name: 'hotel-dish-type',
            component: () => import('@/views/hg-hotel/dish/DishType'),
            meta: { title: '菜品类型', keepAlive: true, permission: ['hotel-dish-type'] }
          },
          {
            path: '/hotel/combo/index',
            name: 'hotel-combo-index',
            redirect: '/hotel/combo/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '套餐管理', keepAlive: true },
            children: [
              {
                path: '/hotel/combo/list',
                name: 'hotel-combo-list',
                component: () => import('@/views/hg-hotel/dish/Combo'),
                meta: { title: '套餐列表', keepAlive: true, permission: ['hotel-combo-list'] }
              },
              {
                path: '/hotel/combo/form',
                name: 'hotel-combo-form',
                component: () => import('@/views/hg-hotel/dish/ComboForm'),
                meta: { title: '添加套餐', keepAlive: true, permission: ['hotel-combo-form'] }
              },
              {
                path: '/hotel/combo/form/:id',
                name: 'hotel-combo-form-edit',
                component: () => import('@/views/hg-hotel/dish/ComboForm'),
                meta: { title: '编辑套餐', keepAlive: true, permission: ['hotel-combo-form-edit'] }
              }
            ]
          }
        ]
      },

      // 台位
      {
        path: '/hotel/table',
        name: 'hotel-table',
        redirect: '/hotel/table/list',
        component: RouteView,
        meta: { title: '台位', keepAlive: true, icon: 'icon-taiweitu', permission: ['hotel-table-list'] },
        children: [
          {
            path: '/hotel/table/list',
            name: 'hotel-table-list',
            component: () => import('@/views/hg-hotel/table/List'),
            meta: { title: '台位列表', keepAlive: true, permission: ['hotel-table-list'] }
          }
        ]
      },

      // 营销
      {
        path: '/hotel/marketing',
        name: 'hotel-marketing',
        redirect: '/hotel/marketing/coupons',
        component: RouteView,
        meta: { title: '营销', keepAlive: true, icon: 'icon-yingxiao', permission: ['hotel-table-coupons', 'hotel-marketing-cards'] },
        children: [
          {
            path: '/hotel/marketing/coupons',
            name: 'hotel-marketing-coupons',
            component: () => import('@/views/hg-hotel/marketing/Coupons'),
            meta: { title: '优惠券', keepAlive: true, permission: ['hotel-marketing-coupons'] }
          },
          {
            path: '/hotel/marketing/cards',
            name: 'hotel-marketing-cards',
            component: () => import('@/views/hg-hotel/marketing/MemberCard'),
            meta: { title: '会员卡', keepAlive: true, permission: ['hotel-marketing-cards'] }
          }
        ]
      },

      // 订单
      {
        path: '/hotel/order',
        name: 'hotel-order',
        redirect: '/hotel/order/list',
        component: RouteView,
        meta: { title: '订单', keepAlive: true, icon: 'icon-dingdan', permission: ['hotel-order-list'] },
        children: [
          {
            path: '/hotel/order/list',
            name: 'hotel-order-list',
            component: () => import('@/views/hg-hotel/order/List'),
            meta: { title: '订单列表', keepAlive: true, permission: ['hotel-order-list'] }
          }
        ]
      },

      // 预订
      {
        path: '/hotel/prerecord',
        name: 'hotel-prerecord',
        redirect: '/hotel/prerecord/list',
        component: RouteView,
        meta: { title: '预订', keepAlive: true, icon: 'icon-dingdan', permission: ['hotel-prerecord-list', 'hotel-finaccount-black'] },
        children: [
          {
            path: '/hotel/prerecord/list',
            name: 'hotel-prerecord-list',
            component: () => import('@/views/hg-hotel/prerecord/List'),
            meta: { title: '预订记录', keepAlive: true, permission: ['hotel-finaccount-list'] }
          },
          {
            path: '/hotel/prerecord/black',
            name: 'hotel-prerecord-black',
            component: () => import('@/views/hg-hotel/prerecord/Black'),
            meta: { title: '黑名单', keepAlive: true, permission: ['hotel-finaccount-black'] }
          }
        ]
      },

      // 财务
      {
        path: '/hotel/finaccount',
        name: 'hotel-finaccount',
        redirect: '/hotel/finaccount/list',
        component: RouteView,
        meta: { title: '财务', keepAlive: true, icon: 'icon-zhangdan-xianxing', permission: ['hotel-finaccount-list', 'hotel-finaccount-bill', 'hotel-finaccount-order', 'hotel-finaccount-goodsout', 'hotel-finaccount-goodsin'] },
        children: [
          {
            path: '/hotel/finaccount/list',
            name: 'hotel-finaccount-list',
            component: () => import('@/views/hg-hotel/finaccount/List'),
            meta: { title: '账户统计', keepAlive: true, permission: ['hotel-finaccount-list'] }
          },
          {
            path: '/hotel/finaccount/bill',
            name: 'hotel-finaccount-bill',
            component: () => import('@/views/hg-hotel/finaccount/Bill'),
            meta: { title: '账单统计', keepAlive: true, permission: ['hotel-finaccount-bill'] }
          },
          {
            path: '/hotel/finaccount/order',
            name: 'hotel-finaccount-order',
            component: () => import('@/views/hg-hotel/finaccount/Order'),
            meta: { title: '订单资金', keepAlive: true, permission: ['hotel-finaccount-order'] }
          },
          {
            path: '/hotel/finaccount/goodsout',
            name: 'hotel-finaccount-goodsout',
            component: () => import('@/views/hg-hotel/finaccount/OutListRecord'),
            meta: { title: '出库统计', keepAlive: true, permission: ['hotel-finaccount-goodsout'] }
          },
          {
            path: '/hotel/finaccount/goodsin',
            name: 'hotel-finaccount-goodsin',
            component: () => import('@/views/hg-hotel/finaccount/InListRecord'),
            meta: { title: '入库统计', keepAlive: true, permission: ['hotel-finaccount-goodsin'] }
          }
        ]
      },

      // 仓库
      {
        path: '/hotel/storage',
        name: 'hotel-storage',
        redirect: '/hotel/storage/out-list',
        component: RouteView,
        meta: { title: '仓库', keepAlive: true, icon: 'icon-cangkukucun', permission: ['hotel-storage-out-list', 'hotel-storage-in-list'] },
        children: [
          {
            path: '/hotel/storage/out-list',
            name: 'hotel-storage-out-list',
            component: () => import('@/views/hg-hotel/storage/OutList'),
            meta: { title: '出库记录', keepAlive: true, permission: ['hotel-storage-list'] }
          },
          {
            path: '/hotel/storage/in-list',
            name: 'hotel-storage-in-list',
            component: () => import('@/views/hg-hotel/storage/InList'),
            meta: { title: '入库记录', keepAlive: true, permission: ['hotel-storage-in-list'] }
          }
        ]
      },

      // 用户
      {
        path: '/hotel/user',
        name: 'hotel-user',
        redirect: '/hotel/user/hotel',
        component: RouteView,
        meta: {
          title: '用户',
          keepAlive: true,
          icon: 'icon-yonghu1',
          permission: [
            'hotel-user-hotel',
            'hotel-user-shareholder',
            'hotel-user-counter',
            'hotel-user-handlers',
            'hotel-user-chef',
            'hotel-user-finance',
            'hotel-user-warehouse',
            'manage-user-waiter',
            'hotel-user-customer'
          ]
        },
        children: [
          {
            path: '/manage/user/hotel',
            name: 'manage-user-hotel',
            component: () => import('@/views/hg-hotel/user/Hotel'),
            meta: { title: '酒店管理员', keepAlive: true, permission: ['manage-user-hotel'] }
          },
          {
            path: '/manage/user/shareholder',
            name: 'manage-user-shareholder',
            component: () => import('@/views/hg-hotel/user/Shareholder'),
            meta: { title: '股东', keepAlive: true, permission: ['manage-user-shareholder'] }
          },
          {
            path: '/manage/user/finance',
            name: 'manage-user-finance',
            component: () => import('@/views/hg-hotel/user/Finance'),
            meta: { title: '财务', keepAlive: true, permission: ['manage-user-finance'] }
          },
          {
            path: '/manage/user/warehouse',
            name: 'manage-user-warehouse',
            component: () => import('@/views/hg-hotel/user/Warehouse'),
            meta: { title: '库管', keepAlive: true, permission: ['manage-user-warehouse'] }
          },
          {
            path: '/manage/user/chef',
            name: 'manage-user-chef',
            component: () => import('@/views/hg-hotel/user/Chef'),
            meta: { title: '厨房', keepAlive: true, permission: ['manage-user-chef'] }
          },
          {
            path: '/manage/user/counter',
            name: 'manage-user-counter',
            component: () => import('@/views/hg-hotel/user/Counter'),
            meta: { title: '吧台', keepAlive: true, permission: ['manage-user-counter'] }
          },
          {
            path: '/manage/user/handlers',
            name: 'manage-user-handlers',
            component: () => import('@/views/hg-hotel/user/Handlers'),
            meta: { title: '客户经理', keepAlive: true, permission: ['manage-user-handlers'] }
          },
          {
            path: '/manage/user/waiter',
            name: 'manage-user-waiter',
            component: () => import('@/views/hg-hotel/user/Waiter'),
            meta: { title: '服务员', keepAlive: true, permission: ['manage-user-waiter'] }
          },
          {
            path: '/manage/user/customer',
            name: 'manage-user-customer',
            component: () => import('@/views/hg-hotel/user/Customer'),
            meta: { title: '客户', keepAlive: true, permission: ['manage-user-customer'] }
          }
        ]
      },
      // 配置
      {
        path: '/hotel/config',
        name: 'hotel-config',
        redirect: '/hotel/config/list',
        component: RouteView,
        meta: { title: '配置', keepAlive: true, icon: 'icon-icon02', permission: ['hotel-config-list'] },
        children: [
          {
            path: '/hotel/config/list',
            name: 'hotel-config-list',
            component: () => import('@/views/hg-hotel/config/Index'),
            meta: { title: '系统配置', keepAlive: true, permission: ['hotel-config-list'] }
          }
        ]
      }
    ]
  }
]

// 股东路由
const shareholderRouterMap = [
  {
    path: '/shareholder',
    name: 'shareholder',
    component: MobileLayout,
    redirect: '/shareholder/tables',
    children: [
      {
        path: '/shareholder/tables',
        name: 'shareholder-tables',
        component: () => import('@/views/hg-shareholder/Tables'),
        meta: { title: '台位', keepAlive: true }
      },
      {
        path: '/shareholder/book/:tableId',
        name: 'shareholder-book',
        component: () => import('@/views/hg-shareholder/Book'),
        meta: { title: '预订', keepAlive: true }
      },
      {
        path: '/shareholder/orders',
        name: 'shareholder-orders',
        component: () => import('@/views/hg-shareholder/Orders'),
        meta: { title: '订单列表', keepAlive: true }
      },
      {
        path: '/shareholder/customers',
        name: 'shareholder-customers',
        component: () => import('@/views/hg-shareholder/Customers'),
        meta: { title: '订单列表', keepAlive: true }
      },
      {
        path: '/shareholder/stats',
        name: 'shareholder-stats',
        component: () => import('@/views/hg-shareholder/Stats'),
        meta: { title: '订单列表', keepAlive: true }
      },
      {
        path: '/shareholder/order/:id',
        name: 'shareholder-order',
        component: () => import('@/views/hg-shareholder/OrderDetail'),
        meta: { title: '订单详情', keepAlive: true }
      },
      {
        path: '/shareholder/dish-list',
        name: 'shareholder-dish-list',
        component: () => import('@/views/hg-shareholder/DishList'),
        meta: { title: '菜品列表', keepAlive: true }
      },
      {
        path: '/shareholder/dish/:id',
        name: 'shareholder-dish',
        component: () => import('@/views/hg-shareholder/AddDish'),
        meta: { title: '菜品', keepAlive: true }
      }
    ]
  }
]

// 吧台路由
const counterRouterMap = [
  {
    path: '/counter',
    name: 'counter',
    component: CounterLayout,
    redirect: '/counter/tables',
    children: [
      {
        path: '/counter/tables',
        name: 'counter-tables',
        component: () => import('@/views/hg-counter/Table'),
        meta: { title: '台位', icon: 'icon-wangzhanguanli', keepAlive: true, permission: ['counter-tables'] }
      },
      {
        path: '/counter/business',
        name: 'counter-business',
        component: () => import('@/views/hg-counter/Business'),
        meta: { title: '营业', icon: 'icon-yeji', keepAlive: true, permission: ['counter-business'] }
      },
      {
        path: '/counter/orders',
        name: 'counter-orders',
        component: () => import('@/views/hg-counter/Order'),
        meta: { title: '查单', icon: 'icon-chadan', keepAlive: true, permission: ['counter-orders'] }
      },
      {
        path: '/counter/stat',
        name: 'counter-stat',
        redirect: '/counter/stat/order',
        component: CounterLayout2,
        meta: { title: '统计', icon: 'icon-shujuzhanshi2', keepAlive: true, permission: ['counter-stat-order', 'counter-stat-waiter-order', 'counter-stat-dish', 'counter-stat-dish-sum', 'counter-stat-handler-order'] },
        children: [
          {
            path: '/counter/stat/order',
            name: 'counter-stat-order',
            component: () => import('@/views/hg-counter/OrderStat'),
            meta: { title: '订单资金统计', keepAlive: true, permission: ['counter-stat-order'] }
          },
          {
            path: '/counter/stat/waiter-order',
            name: 'counter-stat-waiter-order',
            component: () => import('@/views/hg-counter/WaiterOrder'),
            meta: { title: '服务员订单统计', keepAlive: true, permission: ['counter-stat-waiter-order'] }
          },
          {
            path: '/counter/stat/handler-order',
            name: 'counter-stat-handler-order',
            component: () => import('@/views/hg-counter/HandlerOrder'),
            meta: { title: '客户经理订单统计', keepAlive: true, permission: ['counter-stat-handler-order'] }
          },
          {
            path: '/counter/stat/dish',
            name: 'counter-stat-dish',
            component: () => import('@/views/hg-counter/DishStat'),
            meta: { title: '菜品清单统计', keepAlive: true, permission: ['counter-stat-dish'] }
          },
          {
            path: '/counter/stat/dish-sum',
            name: 'counter-stat-dish-sum',
            component: () => import('@/views/hg-counter/DishSumStat'),
            meta: { title: '菜品合计统计', keepAlive: true, permission: ['counter-stat-dish-sum'] }
          },
          {
            path: '/counter/stat/give-dish-sum',
            name: 'counter-stat-give-dish-sum',
            component: () => import('@/views/hg-counter/GiveDishSumStat'),
            meta: { title: '赠菜统计', keepAlive: true, permission: ['counter-stat-give-dish-sum'] }
          }
        ]
      },
      {
        path: '/counter/members',
        name: 'counter-members',
        component: () => import('@/views/hg-counter/Member'),
        meta: { title: '会员', icon: 'icon-VIP', keepAlive: true, permission: ['counter-members'] }
      },
      {
        path: '/counter/member-cards',
        name: 'counter-member-cards',
        component: () => import('@/views/hg-counter/MemberCard'),
        meta: { title: '会员卡', icon: 'icon-VIP', keepAlive: true, permission: ['counter-member-cards'] }
      },
      {
        path: '/counter/bill',
        name: 'counter-bill',
        component: () => import('@/views/hg-counter/Bill'),
        meta: { title: '账单', icon: 'icon-zhangdan-xianxing', keepAlive: true, permission: ['counter-bill'] }
      },
      {
        path: '/counter/goods',
        name: 'counter-goods',
        component: () => import('@/views/hg-counter/Goods'),
        meta: { title: '商品', icon: 'icon-shangpin', keepAlive: true, permission: ['counter-goods'] }
      },
      {
        path: '/counter/dish',
        name: 'counter-dish',
        component: () => import('@/views/hg-counter/DishList'),
        meta: { title: '菜品', icon: 'icon-meishi1', keepAlive: true, permission: ['counter-dish'] }
      },
      {
        path: '/counter/mores',
        name: 'counter-mores',
        component: () => import('@/views/hg-counter/More'),
        meta: { title: '出库', icon: 'icon-cangkukucun', keepAlive: true, permission: ['counter-mores'] }
      },
      {
        path: '/counter/store',
        name: 'counter-store',
        hide: true,
        component: () => import('@/views/hg-counter/Store'),
        meta: { title: '盘库', icon: 'icon-gengduo', keepAlive: true, permission: ['counter-store'] }
      }
    ]
  }
]

// 客户经理路由
const handlersRouterMap = [
  {
    path: '/handlers',
    name: 'handlers',
    component: MobileLayout,
    redirect: '/handlers/index',
    children: [
      {
        path: '/handlers/index',
        name: 'handlers-index',
        component: () => import('@/views/hg-handlers/Index'),
        meta: { title: '首页', keepAlive: true }
      },
      {
        path: '/handlers/tables',
        name: 'handlers-tables',
        component: () => import('@/views/hg-handlers/Tables'),
        meta: { title: '台位', keepAlive: true }
      },
      {
        path: '/handlers/book/:tableId',
        name: 'handlers-book',
        component: () => import('@/views/hg-handlers/Book'),
        meta: { title: '预订', keepAlive: true }
      },
      {
        path: '/handlers/orders',
        name: 'handlers-orders',
        component: () => import('@/views/hg-handlers/Orders'),
        meta: { title: '订单列表', keepAlive: true }
      },
      {
        path: '/handlers/customers',
        name: 'handlers-customers',
        component: () => import('@/views/hg-handlers/Customers'),
        meta: { title: '客户列表', keepAlive: true }
      },
      {
        path: '/handlers/stats',
        name: 'handlers-stats',
        component: () => import('@/views/hg-handlers/Stats'),
        meta: { title: '订单统计', keepAlive: true }
      },
      {
        path: '/handlers/order/:id',
        name: 'handlers-order',
        component: () => import('@/views/hg-handlers/OrderDetail'),
        meta: { title: '订单详情', keepAlive: true }
      },
      {
        path: '/handlers/dish-list',
        name: 'handlers-dish-list',
        component: () => import('@/views/hg-handlers/DishList'),
        meta: { title: '菜品列表', keepAlive: true }
      },
      {
        path: '/handlers/dish/:id',
        name: 'handlers-dish',
        component: () => import('@/views/hg-handlers/AddDish'),
        meta: { title: '菜品', keepAlive: true }
      },
      {
        path: '/handlers/my',
        name: 'handlers-my',
        component: () => import('@/views/hg-handlers/My'),
        meta: { title: '我的', keepAlive: true }
      }
    ]
  }
]

// 厨师路由
const chefRouterMap = [
  {
    path: '/chef',
    name: 'chef',
    component: BasicLayout,
    redirect: '/chef/dashboard',
    children: [
      // 数据
      {
        path: '/chef/dashboard',
        name: 'chef-dashboard',
        redirect: '/chef/dashboard/analysis',
        component: RouteView,
        meta: { title: '数据', keepAlive: true, icon: 'icon-shujuzhanshi2', permission: ['chef-dashboard-analysis', 'chef-dashboard-vacate', 'chef-dashboard-dish-sum', 'chef-dashboard-dish-goods'] },
        children: [
          {
            path: '/chef/dashboard/analysis',
            name: 'chef-dashboard-analysis',
            component: () => import('@/views/hg-chef/dashboard/Analysis'),
            meta: { title: '数据统计', keepAlive: true, permission: ['chef-dashboard-analysis'] }
          },
          {
            path: '/chef/dashboard/combo',
            name: 'chef-dashboard-combo',
            component: () => import('@/views/hg-chef/dashboard/Combo'),
            meta: { title: '套餐统计', keepAlive: true, permission: ['chef-dashboard-combo'] }
          },
          {
            path: '/chef/dashboard/dish',
            name: 'chef-dashboard-dish',
            component: () => import('@/views/hg-chef/dashboard/DishStat'),
            meta: { title: '菜品清单统计', keepAlive: true, permission: ['chef-dashboard-dish'] }
          },
          {
            path: '/chef/dashboard/dish-sum',
            name: 'chef-dashboard-dish-sum',
            component: () => import('@/views/hg-chef/dashboard/DishSumStat'),
            meta: { title: '菜品合计统计', keepAlive: true, permission: ['chef-dashboard-dish-sum'] }
          },
          {
            path: '/chef/dashboard/dish-goods',
            name: 'chef-dashboard-dish-goods',
            component: () => import('@/views/hg-chef/dashboard/DishGoodsStat'),
            meta: { title: '主辅料统计', keepAlive: true, permission: ['chef-dashboard-dish-goods'] }
          }
        ]
      },

      // 商品
      {
        path: '/chef/goods',
        name: 'chef-goods',
        redirect: '/chef/goods/list',
        component: RouteView,
        meta: { title: '商品', keepAlive: true, icon: 'icon-shangpin', permission: ['chef-goods-list', 'chef-goods-category'] },
        children: [
          {
            path: '/chef/goods/list',
            name: 'chef-goods-list',
            component: () => import('@/views/hg-chef/goods/List'),
            meta: { title: '商品列表', keepAlive: true, permission: ['chef-goods-list'] }
          },
          {
            path: '/chef/goods/category',
            name: 'chef-goods-category',
            component: () => import('@/views/hg-chef/goods/Category'),
            meta: { title: '商品分类', keepAlive: true, permission: ['chef-goods-category'] }
          }
        ]
      },

      // 菜品
      {
        path: '/chef/dish',
        name: 'chef-dish',
        redirect: '/chef/dish/list',
        component: RouteView,
        meta: {
          title: '菜品',
          keepAlive: true,
          icon: 'icon-meishi1',
          permission: [
            'chef-dish-list',
            'chef-dish-form',
            'chef-dish-form-edit',
            'chef-combo-list',
            'chef-combo-form',
            'chef-combo-form-edit',
            'chef-dish-must',
            'chef-dish-type'
          ]
        },
        children: [
          {
            path: '/chef/dish/index',
            name: 'chef-dish-index',
            redirect: '/chef/dish/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '菜品管理', keepAlive: true },
            children: [
              {
                path: '/chef/dish/list',
                name: 'chef-dish-list',
                component: () => import('@/views/hg-chef/dish/List'),
                meta: { title: '菜品列表', keepAlive: true, permission: ['chef-dish-list'] }
              },
              {
                path: '/chef/dish/form',
                name: 'chef-dish-form',
                component: () => import('@/views/hg-chef/dish/Form'),
                meta: { title: '添加菜品', keepAlive: true, permission: ['chef-dish-form'] }
              },
              {
                path: '/chef/dish/form/:id',
                name: 'chef-dish-form-edit',
                component: () => import('@/views/hg-chef/dish/Form'),
                meta: { title: '编辑菜品', keepAlive: true, permission: ['chef-dish-form-edit'] }
              }
            ]
          },
          {
            path: '/chef/dish/must',
            name: 'chef-dish-must',
            component: () => import('@/views/hg-chef/dish/Must'),
            meta: { title: '商转菜品', keepAlive: true, permission: ['chef-dish-must'] }
          },
          {
            path: '/chef/dish/type',
            name: 'chef-dish-type',
            component: () => import('@/views/hg-chef/dish/DishType'),
            meta: { title: '菜品类型', keepAlive: true, permission: ['chef-dish-type'] }
          },
          {
            path: '/chef/combo/index',
            name: 'chef-combo-index',
            redirect: '/chef/combo/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '套餐管理', keepAlive: true },
            children: [
              {
                path: '/chef/combo/list',
                name: 'chef-combo-list',
                component: () => import('@/views/hg-chef/dish/Combo'),
                meta: { title: '套餐列表', keepAlive: true, permission: ['chef-combo-list'] }
              },
              {
                path: '/chef/combo/form',
                name: 'chef-combo-form',
                component: () => import('@/views/hg-chef/dish/ComboForm'),
                meta: { title: '添加套餐', keepAlive: true, permission: ['chef-combo-form'] }
              },
              {
                path: '/chef/combo/form/:id',
                name: 'chef-combo-form-edit',
                component: () => import('@/views/hg-chef/dish/ComboForm'),
                meta: { title: '编辑套餐', keepAlive: true, permission: ['chef-combo-form-edit'] }
              }
            ]
          }
        ]
      },

      // 订单
      {
        path: '/chef/order',
        name: 'chef-order',
        redirect: '/chef/order/list',
        component: RouteView,
        meta: { title: '订单', keepAlive: true, icon: 'icon-dingdan', permission: ['chef-order-list', 'chef-order-detail'] },
        children: [
          {
            path: '/chef/order/index',
            name: 'chef-order-index',
            redirect: '/chef/order/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '订单管理', keepAlive: true },
            children: [
              {
                path: '/chef/order/list',
                name: 'chef-order-list',
                component: () => import('@/views/hg-chef/order/List'),
                meta: { title: '订单列表', keepAlive: true, permission: ['chef-order-list'] }
              },
              {
                path: '/chef/order/detail/:id',
                name: 'chef-order-detail',
                component: () => import('@/views/hg-chef/order/Detail'),
                meta: { title: '订单详情', keepAlive: true, permission: ['chef-order-detail'] }
              }
            ]
          }
        ]
      },

      // 采购
      {
        path: '/chef/plan',
        name: 'chef-plan',
        redirect: '/chef/plan/list',
        component: RouteView,
        meta: { title: '采购', keepAlive: true, icon: 'icon-caigou-xianxing', permission: ['chef-plan-list', 'chef-plan-detailedlist'] },
        children: [
          {
            path: '/chef/plan/index',
            name: 'chef-plan-index',
            redirect: '/chef/plan/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '采购计划', keepAlive: true },
            children: [
              {
                path: '/chef/plan/list',
                name: 'chef-plan-list',
                component: () => import('@/views/hg-chef/plan/List'),
                meta: { title: '计划列表', keepAlive: true, permission: ['chef-plan-list'] }
              },
              {
                path: '/chef/plan/detailedlist/:planId',
                name: 'chef-plan-detailedlist',
                component: () => import('@/views/hg-chef/plan/DetailedList'),
                meta: { title: '计划清单', keepAlive: true, permission: ['chef-plan-detailedlist'] }
              }
            ]
          }
        ]
      },

      // 仓库
      {
        path: '/chef/storage',
        name: 'chef-storage',
        redirect: '/chef/storage/out-list',
        component: RouteView,
        meta: { title: '仓库', keepAlive: true, icon: 'icon-cangkukucun', permission: ['chef-storage-out-list', 'chef-storage-second'] },
        children: [
          {
            path: '/chef/storage/out-list',
            name: 'chef-storage-out-list',
            component: () => import('@/views/hg-chef/storage/OutList'),
            meta: { title: '出库记录', keepAlive: true, permission: ['chef-storage-out-list'] }
          },
          {
            path: '/chef/storage/second',
            name: 'chef-storage-second',
            component: () => import('@/views/hg-chef/storage/Second'),
            meta: { title: '二级库存', keepAlive: true, permission: ['chef-storage-second'] }
          }
        ]
      }
    ]
  }
]

// 财务路由
const financeRouterMap = [
  {
    path: '/finance',
    name: 'finance',
    component: BasicLayout,
    redirect: '/finance/dashboard',
    children: [
      // 数据
      {
        path: '/finance/dashboard',
        name: 'finance-dashboard',
        component: () => import('@/views/hg-finance/Analysis'),
        meta: { title: '数据统计', keepAlive: true, icon: 'icon-shujuzhanshi2', permission: ['finance-dashboard'] }
      },
      {
        path: '/finance/list',
        name: 'finance-list',
        component: () => import('@/views/hg-finance/List'),
        meta: { title: '账户统计', icon: 'icon-zhanghuyue', keepAlive: true, permission: ['finance-list'] }
      },
      {
        path: '/finance/bill',
        name: 'finance-bill',
        component: () => import('@/views/hg-finance/Bill'),
        meta: { title: '账单统计', icon: 'icon-zhangdan-xianxing', keepAlive: true, permission: ['finance-bill'] }
      },
      {
        path: '/finance/recharge',
        name: 'finance-recharge',
        component: () => import('@/views/hg-finance/Recharge'),
        meta: { title: '充值统计', icon: 'icon-zhanghuyue', keepAlive: true, permission: ['finance-recharge'] }
      },
      {
        path: '/finance/combo',
        name: 'finance-combo',
        component: () => import('@/views/hg-finance/Combo'),
        meta: { title: '套餐统计', icon: 'icon-jiacai', keepAlive: true, permission: ['finance-combo'] }
      },
      {
        path: '/finance/dish',
        name: 'finance-dish',
        component: () => import('@/views/hg-finance/DishStat'),
        meta: { title: '菜品清单统计', icon: 'icon-jiacai', keepAlive: true, permission: ['finance-dish'] }
      },
      {
        path: '/finance/dish-sum',
        name: 'finance-dish-sum',
        component: () => import('@/views/hg-finance/DishSumStat'),
        meta: { title: '菜品合计统计', icon: 'icon-jiacai', keepAlive: true, permission: ['finance-dish-sum'] }
      },
      {
        path: '/finance/dish-goods',
        name: 'finance-dish-goods',
        component: () => import('@/views/hg-finance/DishGoodsStat'),
        meta: { title: '主辅料统计', icon: 'icon-jiacai', keepAlive: true, permission: ['finance-dish-goods'] }
      },
      {
        path: '/finance/goods',
        name: 'finance-goods',
        component: () => import('@/views/hg-finance/Goods'),
        meta: { title: '商品库存', icon: 'icon-shenqingjilu', keepAlive: true, permission: ['finance-goods'] }
      },
      {
        path: '/finance/order-list',
        name: 'finance-order-list',
        component: () => import('@/views/hg-finance/OrderList'),
        meta: { title: '订单列表', icon: 'icon-shenqingjilu', keepAlive: true, permission: ['finance-order-list'] }
      },
      {
        path: '/finance/order',
        name: 'finance-order',
        component: () => import('@/views/hg-finance/Order'),
        meta: { title: '订单资金统计', icon: 'icon-shenqingjilu', keepAlive: true, permission: ['finance-order'] }
      },
      {
        path: '/finance/waiter-order',
        name: 'finance-waiter-order',
        component: () => import('@/views/hg-finance/WaiterOrder'),
        meta: { title: '服务员订单统计', icon: 'icon-shenqingjilu', keepAlive: true, permission: ['finance-waiter-order'] }
      },
      {
        path: '/finance/handler-order',
        name: 'finance-handler-order',
        component: () => import('@/views/hg-finance/HandlerOrder'),
        meta: { title: '客户经理订单统计', icon: 'icon-shenqingjilu', keepAlive: true, permission: ['finance-handler-order'] }
      },
      {
        path: '/finance/out-list',
        name: 'finance-out-list',
        component: () => import('@/views/hg-finance/OutListRecord'),
        meta: { title: '出库统计', icon: 'icon-chuku', keepAlive: true, permission: ['finance-list'] }
      },
      {
        path: '/finance/in-list',
        name: 'finance-in-list',
        component: () => import('@/views/hg-finance/InListRecord'),
        meta: { title: '入库统计', icon: 'icon-ruku', keepAlive: true, permission: ['finance-in-list'] }
      },
      {
        path: '/finance/supplier',
        name: 'finance-supplier',
        component: () => import('@/views/hg-warehouse/supplier/Finance'),
        meta: { title: '供货商资金统计', icon: 'icon-gonghuoshang', keepAlive: true, permission: ['finance-supplier'] }
      },
      {
        path: '/finance/stat/give-dish-sum',
        name: 'finance-stat-give-dish-sum',
        component: () => import('@/views/hg-finance/GiveDishSumStat'),
        meta: { title: '赠菜统计', icon: 'icon-gonghuoshang', keepAlive: true, permission: ['finance-stat-give-dish-sum'] }
      }
    ]
  }
]

// 库管路由
const warehouseRouterMap = [
  {
    path: '/warehouse',
    name: 'warehouse',
    component: BasicLayout,
    redirect: '/warehouse/dashboard',
    children: [
      // 数据
      {
        path: '/warehouse/dashboard',
        name: 'warehouse-dashboard',
        redirect: '/warehouse/dashboard/analysis',
        component: RouteView,
        meta: { title: '数据', keepAlive: true, icon: 'icon-shujuzhanshi2', permission: ['warehouse-dashboard-analysis', 'warehouse-dashboard-vacate'] },
        children: [
          {
            path: '/warehouse/dashboard/analysis',
            name: 'warehouse-dashboard-analysis',
            component: () => import('@/views/hg-warehouse/dashboard/Analysis'),
            meta: { title: '数据统计', keepAlive: true, permission: ['warehouse-dashboard-analysis'] }
          }
        ]
      },

      // 商品
      {
        path: '/warehouse/goods',
        name: 'warehouse-goods',
        redirect: '/warehouse/goods/list',
        component: RouteView,
        meta: { title: '商品', keepAlive: true, icon: 'icon-shangpin', permission: ['warehouse-goods-list', 'warehouse-goods-category'] },
        children: [
          {
            path: '/warehouse/goods/list',
            name: 'warehouse-goods-list',
            component: () => import('@/views/hg-warehouse/goods/List'),
            meta: { title: '商品列表', keepAlive: true, permission: ['warehouse-goods-list'] }
          },
          {
            path: '/warehouse/goods/category',
            name: 'warehouse-goods-category',
            component: () => import('@/views/hg-warehouse/goods/Category'),
            meta: { title: '商品分类', keepAlive: true, permission: ['warehouse-goods-category'] }
          }
        ]
      },

      // 采购
      {
        path: '/warehouse/plan',
        name: 'warehouse-plan',
        redirect: '/warehouse/plan/list',
        component: RouteView,
        meta: { title: '采购', keepAlive: true, icon: 'icon-caigou-xianxing', permission: ['warehouse-plan-list', 'warehouse-plan-detailedlist'] },
        children: [
          {
            path: '/warehouse/plan/index',
            name: 'warehouse-plan-index',
            redirect: '/warehouse/plan/list',
            component: RouteView,
            hideChildren: true,
            meta: { title: '采购计划', keepAlive: true },
            children: [
              {
                path: '/warehouse/plan/list',
                name: 'warehouse-plan-list',
                component: () => import('@/views/hg-warehouse/plan/List'),
                meta: { title: '计划列表', keepAlive: true, permission: ['warehouse-plan-list'] }
              },
              {
                path: '/warehouse/plan/detailedlist/:planId',
                name: 'warehouse-plan-detailedlist',
                component: () => import('@/views/hg-warehouse/plan/DetailedList'),
                meta: { title: '计划清单', keepAlive: true, permission: ['warehouse-plan-detailedlist'] }
              }
            ]
          }
        ]
      },

      // 仓库
      {
        path: '/warehouse/storage',
        name: 'warehouse-storage',
        redirect: '/warehouse/storage/out-list',
        component: RouteView,
        meta: { title: '仓库', keepAlive: true, icon: 'icon-cangkukucun', permission: ['warehouse-storage-out-list', 'warehouse-storage-in-list', 'warehouse-storage-second'] },
        children: [
          {
            path: '/warehouse/storage/out-list',
            name: 'warehouse-storage-out-list',
            component: () => import('@/views/hg-warehouse/storage/OutList'),
            meta: { title: '出库记录', keepAlive: true, permission: ['warehouse-storage-out-list'] }
          },
          {
            path: '/warehouse/storage/in-list',
            name: 'warehouse-storage-in-list',
            component: () => import('@/views/hg-warehouse/storage/InList'),
            meta: { title: '入库记录', keepAlive: true, permission: ['warehouse-storage-in-list'] }
          },
          {
            path: '/warehouse/storage/second',
            name: 'warehouse-storage-second',
            component: () => import('@/views/hg-warehouse/storage/Second'),
            meta: { title: '二级库存', keepAlive: true, permission: ['warehouse-storage-second'] }
          }
        ]
      },

      // 供货商
      {
        path: '/warehouse/supplier',
        name: 'warehouse-supplier',
        redirect: '/warehouse/supplier/list',
        component: RouteView,
        meta: { title: '供货商', keepAlive: true, icon: 'icon-gonghuoshang', permission: ['warehouse-supplier-list', 'warehouse-supplier-category'] },
        children: [
          {
            path: '/warehouse/supplier/list',
            name: 'warehouse-supplier-list',
            component: () => import('@/views/hg-warehouse/supplier/List'),
            meta: { title: '供货商列表', keepAlive: true, permission: ['warehouse-supplier-list'] }
          },
          {
            path: '/warehouse/supplier/finance',
            name: 'warehouse-supplier-finance',
            component: () => import('@/views/hg-warehouse/supplier/Finance'),
            meta: { title: '供货商资金统计', keepAlive: true, permission: ['warehouse-supplier-finance'] }
          }
        ]
      }
    ]
  }
]

// 客户路由
const customerRouterMap = [
  {
    path: '/customer',
    name: 'customer',
    component: MobileLayout,
    redirect: '/customer/index',
    children: [
      {
        path: '/customer/index',
        name: 'customer-index',
        component: () => import('@/views/hg-customer/Index'),
        meta: { title: '预订', keepAlive: true }
      },
      {
        path: '/customer/my',
        name: 'customer-my',
        component: () => import('@/views/hg-customer/My'),
        meta: { title: '我的', keepAlive: true }
      },
      {
        path: '/customer/recharge',
        name: 'customer-recharge',
        component: () => import('@/views/hg-customer/Recharge'),
        meta: { title: '充值中心', keepAlive: true }
      },
      {
        path: '/customer/orders',
        name: 'customer-orders',
        component: () => import('@/views/hg-customer/Orders'),
        meta: { title: '我的订单', keepAlive: true }
      },
      {
        path: '/customer/coupons',
        name: 'customer-coupons',
        component: () => import('@/views/hg-customer/Coupons'),
        meta: { title: '优惠券', keepAlive: true }
      },
      {
        path: '/customer/wallet',
        name: 'customer-wallet',
        component: () => import('@/views/hg-customer/Wallet'),
        meta: { title: '钱包', keepAlive: true }
      },
      {
        path: '/customer/paycode/:id',
        name: 'customer-paycode',
        component: () => import('@/views/hg-customer/Paycode'),
        meta: { title: '付款码', keepAlive: true }
      },
      {
        path: '/customer/order/:id',
        name: 'customer-order',
        component: () => import('@/views/hg-customer/OrderDetail'),
        meta: { title: '订单详情', keepAlive: true }
      }
    ]
  }
]

const mobile = [
  {
    path: '/',
    name: 'index',
    redirect: '/hotel',
    component: RouteView
  },
  {
    path: '/scan',
    name: 'scan',
    component: MobileLayout
  },
  {
    path: '/waimai',
    name: 'waimai',
    component: MobileLayout
  },
  {
    path: '/customer',
    name: 'customer',
    component: MobileLayout
  },
  {
    path: '/shareholder',
    name: 'shareholder',
    component: MobileLayout
  },
  {
    path: '/handlers',
    name: 'handlers',
    component: MobileLayout
  },
  {
    path: '/shareholder',
    component: MobileLayout,
    redirect: '/shareholder/login',
    children: [
      {
        path: '/shareholder/login',
        name: 'shareholder-login',
        component: () => import('@/views/hg-public/logins/Shareholder'),
        meta: { title: '股东管理登录' }
      }
    ]
  },
  {
    path: '/handlers',
    component: MobileLayout,
    redirect: '/handlers/login',
    children: [
      {
        path: '/handlers/login',
        name: 'handlers-login',
        component: () => import('@/views/hg-public/logins/Handlers'),
        meta: { title: '客户经理管理登录' }
      }
    ]
  },
  {
    path: '/customer',
    component: RouteView,
    redirect: '/customer/login',
    children: [
      {
        path: '/customer/login',
        name: 'customer-login',
        component: () => import('@/views/hg-public/logins/Customer'),
        meta: { title: '登录' }
      },
      {
        path: '/customer/regist',
        name: 'customer-regist',
        component: () => import('@/views/hg-public/logins/CustomerRegist'),
        meta: { title: '注册' }
      },
      {
        path: '/customer/login2',
        name: 'customer-login2',
        component: () => import('@/views/hg-public/logins/Customer2'),
        meta: { title: '测试登录' }
      }
    ]
  },
  {
    path: '/scan',
    component: RouteView,
    redirect: '/scan/login',
    children: [
      {
        path: '/scan/login',
        name: 'scan-login',
        component: () => import('@/views/hg-public/scan/Customer'),
        meta: { title: '登录' }
      },
      {
        path: '/scan/regist',
        name: 'scan-regist',
        component: () => import('@/views/hg-public/scan/CustomerRegist'),
        meta: { title: '注册' }
      },
      {
        path: '/scan/login2',
        name: 'scan-login2',
        component: () => import('@/views/hg-public/scan/Customer2'),
        meta: { title: '测试登录' }
      }
    ]
  },
  {
    path: '/waimai',
    component: RouteView,
    redirect: '/waimai/login',
    children: [
      {
        path: '/waimai/login',
        name: 'waimai-login',
        component: () => import('@/views/hg-public/scan/WaimaiCustomer'),
        meta: { title: '登录' }
      },
      {
        path: '/waimai/regist',
        name: 'waimai-regist',
        component: () => import('@/views/hg-public/scan/WaimaiCustomerRegist'),
        meta: { title: '注册' }
      },
      {
        path: '/waimai/login2',
        name: 'waimai-login2',
        component: () => import('@/views/hg-public/scan/WaimaiCustomer2'),
        meta: { title: '测试登录' }
      }
    ]
  }
]

const manage = [
  {
    path: '/',
    name: 'index',
    redirect: '/hotel',
    component: RouteView
  },
  {
    path: '/manage',
    name: 'manage',
    component: BasicLayout
  },
  {
    path: '/hotel',
    name: 'hotel',
    component: BasicLayout
  },
  {
    path: '/counter',
    name: 'counter',
    component: BasicLayout
  },
  {
    path: '/chef',
    name: 'chef',
    component: BasicLayout
  },
  {
    path: '/finance',
    name: 'finance',
    component: BasicLayout
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: BasicLayout
  },
  {
    path: '/manage',
    component: UserLayout,
    redirect: '/manage/login',
    children: [
      {
        path: '/manage/login',
        name: 'manage-login',
        component: () => import('@/views/hg-public/manage/Login'),
        meta: { title: '平台管理登录' }
      }
    ]
  },
  {
    path: '/hotel',
    component: UserLayout,
    redirect: '/hotel/login',
    children: [
      {
        path: '/hotel/login',
        name: 'hotel-login',
        component: () => import('@/views/hg-public/logins/Hotel'),
        meta: { title: '酒店管理登录' }
      }
    ]
  },
  {
    path: '/counter',
    component: UserLayout,
    redirect: '/counter/login',
    children: [
      {
        path: '/counter/login',
        name: 'counter-login',
        component: () => import('@/views/hg-public/logins/Counter'),
        meta: { title: '吧台管理登录' }
      }
    ]
  },

  {
    path: '/chef',
    component: UserLayout,
    redirect: '/chef/login',
    children: [
      {
        path: '/chef/login',
        name: 'chef-login',
        component: () => import('@/views/hg-public/logins/Chef'),
        meta: { title: '厨师管理登录' }
      }
    ]
  },
  {
    path: '/finance',
    component: UserLayout,
    redirect: '/finance/login',
    children: [
      {
        path: '/finance/login',
        name: 'finance-login',
        component: () => import('@/views/hg-public/logins/Finance'),
        meta: { title: '财务管理登录' }
      }
    ]
  },
  {
    path: '/warehouse',
    component: UserLayout,
    redirect: '/warehouse/login',
    children: [
      {
        path: '/warehouse/login',
        name: 'warehouse-login',
        component: () => import('@/views/hg-public/logins/Warehouse'),
        meta: { title: '库管管理登录' }
      }
    ]
  }
]
const constantRouterMap = [
  {
    path: '/account',
    name: 'account',
    component: BasicLayout,
    redirect: '/account/index',
    children: [
      {
        path: '/account/index',
        name: 'account-index',
        redirect: '/account/info',
        component: () => import('@/views/hg-public/account/Index'),
        meta: { title: '账号管理', keepAlive: true },
        children: [
          {
            path: '/account/info',
            name: 'account-info',
            component: () => import('@/views/hg-public/account/Info'),
            meta: { title: '我的信息', keepAlive: true }
          }
        ]
      }
    ]
  }
]

const notfound = [
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/hg-public/exception/404')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/hg-public/exception/404')
  }
]

const scanCodeRoutes = [
  {
    path: '/scan',
    component: MobileLayout,
    redirect: '/scan/index',
    children: [
      {
        path: '/scan/index',
        name: 'scan-index',
        component: () => import('@/views/hg-scan/Index2'),
        meta: { title: '扫码点餐' }
      },
      {
        path: '/scan/index2',
        name: 'scan-index2',
        component: () => import('@/views/hg-scan/Index'),
        meta: { title: '扫码点餐2' }
      },
      {
        path: '/scan/order',
        name: 'scan-order',
        component: () => import('@/views/hg-scan/Order'),
        meta: { title: '我的菜单' }
      },
      {
        path: '/scan/people',
        name: 'scan-people',
        component: () => import('@/views/hg-scan/People'),
        meta: { title: '就餐人数' }
      }
    ]
  }
]

const waimaiScanCodeRoutes = [
  {
    path: '/waimai',
    component: MobileLayout,
    redirect: '/waimai/index',
    children: [
      {
        path: '/waimai/index',
        name: 'waimai-index',
        component: () => import('@/views/hg-scan/waimai/Index'),
        meta: { title: '扫码点餐' }
      },
      {
        path: '/waimai/orders',
        name: 'waimai-orders',
        component: () => import('@/views/hg-scan/waimai/Orders'),
        meta: { title: '订单列表' }
      },
      {
        path: '/waimai/order/:id',
        name: 'waimai-order',
        component: () => import('@/views/hg-scan/waimai/Order'),
        meta: { title: '订单详情' }
      }
    ]
  }
]

// if (defaultConfig.mode === 'test') {
//   constantRouterMap.push(...manage)
// } else if (defaultConfig.mode === 'mtest') {
//   constantRouterMap.push(...mobile)
// } else if (defaultConfig.mode === 'www') {
//   constantRouterMap.push(...manage)
// } else if (defaultConfig.mode === 'm') {
//   constantRouterMap.push(...mobile)
// } else if (defaultConfig.mode === 'local') {
//   constantRouterMap.push(...manage)
//   constantRouterMap.push(...mobile)
// }

constantRouterMap.push(...manage)
constantRouterMap.push(...mobile)

constantRouterMap.push(...scanCodeRoutes)
constantRouterMap.push(...waimaiScanCodeRoutes)
constantRouterMap.push(...notfound)

const hasHiddenChildren = ['warehouse-plan-index', 'chef-plan-index', 'chef-dish-index', 'chef-combo-index', 'chef-order-index', 'hotel-dish-index', 'hotel-combo-index']

export {
  constantRouterMap,
  asyncRouterMap,
  hasHiddenChildren,
  hotelRouterMap,
  shareholderRouterMap,
  counterRouterMap,
  handlersRouterMap,
  chefRouterMap,
  financeRouterMap,
  warehouseRouterMap,
  customerRouterMap,
  notfound
}
