const modes = {
  // eslint-disable-next-line no-undef
  test: TEST_MODE,
  // eslint-disable-next-line no-undef
  www: WWW_MODE,
  // eslint-disable-next-line no-undef
  local: LOCAL_MODE
}

// eslint-disable-next-line no-undef
const mode = MODE

const config = {
  // host: process.env.VUE_APP_HOST,
  // api_host: process.env.VUE_APP_API_HOST,
  // rc_host: process.env.VUE_APP_RC_HOST,

  mode: mode,
  host: modes[mode].host,
  api_host: modes[mode].api,
  rc_host: modes[mode].rc,
  ws_host: modes[mode].ws,

  version: process.env.VUE_APP_VERSION,
  check_auth: process.env.VUE_APP_CHECK_AUTH,
  project_token: process.env.VUE_APP_PROJECT_TOKEN,
  title: process.env.VUE_APP_PROJECT_TITLE,
  company: process.env.VUE_APP_COMPANY,
  store_id: process.env.VUE_APP_STORE_ID,
  app_id: process.env.VUE_APP_APP_ID,

  // 角色类型
  roleNames: ['manage', 'manage', 'hotel', 'shareholder', 'finance', 'warehouse', 'chef', 'counter', 'handlers', 'customer']
}

export default config
