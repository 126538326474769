export class GuidClass {
  date = new Date()
  newGUID () {
    this.date = new Date()
    var guidStr = ''
    const sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16)
    const sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16)
    for (var i = 0; i < 9; i++) {
      guidStr += Math.floor(Math.random() * 16).toString(16)
    }
    guidStr += sexadecimalDate
    guidStr += sexadecimalTime
    while (guidStr.length < 32) {
      guidStr += Math.floor(Math.random() * 16).toString(16)
    }
    return this.formatGUID(guidStr)
  }

  getGUIDDate () {
    return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay())
  }

  getGUIDTime () {
    return this.addZero(this.date.getHours()) + this.addZero(this.date.getMinutes()) + this.addZero(this.date.getSeconds()) + this.addZero(parseInt(this.date.getMilliseconds() / 10))
  }

  addZero (num) {
    if (Number(num).toString() !== 'NaN' && num >= 0 && num < 10) {
      return '0' + Math.floor(num)
    } else {
      return num.toString()
    }
  }

  hexadecimal (num, x, y) {
    if (y !== undefined) {
      return parseInt(num.toString(), y).toString(x)
    } else {
      return parseInt(num.toString()).toString(x)
    }
  }

  formatGUID (guidStr) {
    var str1 = guidStr.slice(0, 8) + '-'
    var str2 = guidStr.slice(8, 12) + '-'
    var str3 = guidStr.slice(12, 16) + '-'
    var str4 = guidStr.slice(16, 20) + '-'
    var str5 = guidStr.slice(20)
    return str1 + str2 + str3 + str4 + str5
  }
}
