<template>
  <a-layout style="height: 100vh">
    <a-layout-sider style="background: #fafafa" :width="250 * rate">
      <div class="slider">
        <div class="logo">
          <img :src="store.logoAcross" :alt="store.title" />
        </div>
        <EMenu />
      </div>
    </a-layout-sider>
    <a-layout style="background: #fafafa">
      <a-layout-content>
        <div class="router-body">
          <div class="bg">
            <img src="@/assets/counter/header-bg.png" alt="" />
          </div>
          <div class="router-header">
            <EHeader />
          </div>
          <div class="router-content">
            <router-view></router-view>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { defineComponent, ref } from 'vue'
import EMenu from '@/components/counter/Menu'
import EHeader from '@/components/counter/Header'

export default defineComponent({
  setup () {
    const slideWidth = ref(250)
    const rate = ref(1)
    return {
      slideWidth,
      rate
    }
  },
  created () {
    this.rate = document.documentElement.clientWidth / 1920
    document.documentElement.style.fontSize =
      document.documentElement.clientWidth / 19.2 + 'px'
  },
  components: {
    EMenu,
    EHeader
  },
  computed: {
    store () {
      return this.$store.state.user.storeInfo
    }
  }
})
</script>
<style lang="less" scoped>
.slider {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 0 0.5rem 0.5rem 0;
  position: relative;
  padding-top: 1.5rem;
  .logo {
    width: 100%;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    img {
      display: block;
      max-width: 100%;
    }
  }
}
.router-body {
  height: 100%;
  width: 100%;
  padding: 0.7rem 0 0 0.5rem;
  box-sizing: border-box;
  position: relative;
}
.router-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 5.88rem;
  z-index: 8;
  img {
    display: block;
    width: 100%;
  }
}
.router-content {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
