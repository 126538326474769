import { BaseClass } from './base'

export class TableClass extends BaseClass {
  modelRef = {
    name: '',
    peopleCount: 0,
    state: 0,
    sortNum: 0
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入名称'
      }
    ]
  }

  gets (params) {
    return this.get(this.table, params)
  }

  all (params) {
    return this.get(this.table + '/TableOrders', params)
  }

  detail (id) {
    return this.get(this.table + `/${id}`)
  }

  create (data) {
    return this.post(this.table, data)
  }

  update (data) {
    return this.put(this.table, data)
  }

  del (id) {
    return this.delete(this.table + `/${id}`)
  }

  sort (id, sort) {
    return this.get(this.table + `/${id}/${sort}`)
  }

  state (id, state) {
    return this.put(this.table + '/ChangeState', { id, state })
  }

  orderCount (bookDate) {
    return this.get(this.table + '/TableOrderCount', { bookDate })
  }

  orderInfo (bookDate) {
    return this.get(this.table + '/GetOrderInfo', { bookDate })
  }

  qrcode (id) {
    return this.get(this.table + `/CreateQrcode/${id}`)
  }
}
