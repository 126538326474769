import router from '@/router'
import NProgress from 'nprogress' // progress bar
import '@/components/styles/nprogress.less'
import { setDocumentTitle, checkPath } from '@/utils'
import defaultConfig from '@/config/default.config'
import store from '@/store'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import {
  asyncRouterMap,
  hotelRouterMap,
  shareholderRouterMap,
  counterRouterMap,
  handlersRouterMap,
  chefRouterMap,
  financeRouterMap,
  warehouseRouterMap,
  customerRouterMap

} from '@/config/router.config'
NProgress.configure({ showSpinner: false })

// 白名单，不需要验证
const whiteList = [
  'manage-login',
  'hotel-login',
  'shareholder-login',
  'finance-login',
  'warehouse-login',
  'chef-login',
  'counter-login',
  'handlers-login',
  'customer-login',
  'customer-login2',
  'customer-regist',
  'scan-login',
  'scan-login2',
  'scan-regist',
  'waimai-login',
  'waimai-login2',
  'waimai-regist',
  'customer-index'
]

// 登录地址
const loginList = [
  'shareholder-login',
  'handlers-login',
  'scan-login',
  'scan-login2',
  'scan-regist',
  'waimai-login',
  'waimai-login2',
  'waimai-regist',
  'customer-login',
  'customer-login2',
  'customer-regist',
  'customer-index'
]
// 角色类型
const roleNames = defaultConfig.roleNames
// 角色路由
const roleRouters = {
  manage: asyncRouterMap,
  hotel: hotelRouterMap,
  shareholder: shareholderRouterMap,
  finance: financeRouterMap,
  warehouse: warehouseRouterMap,
  chef: chefRouterMap,
  counter: counterRouterMap,
  handlers: handlersRouterMap,
  customer: customerRouterMap
}

// if (defaultConfig.mode === 'test' || defaultConfig.mode === 'www') {
//   roleRouters = {
//     manage: asyncRouterMap,
//     hotel: hotelRouterMap,
//     finance: financeRouterMap,
//     warehouse: warehouseRouterMap,
//     chef: chefRouterMap,
//     counter: counterRouterMap,
//     shareholder: [],
//     handlers: [],
//     customer: []
//   }
// } else if (defaultConfig.mode === 'mtest' || defaultConfig.mode === 'm') {
//   roleRouters = {
//     manage: [],
//     hotel: [],
//     finance: [],
//     warehouse: [],
//     chef: [],
//     counter: [],
//     shareholder: shareholderRouterMap,
//     handlers: handlersRouterMap,
//     customer: customerRouterMap
//   }
// } else {
//   roleRouters = {
//     manage: asyncRouterMap,
//     hotel: hotelRouterMap,
//     shareholder: shareholderRouterMap,
//     finance: financeRouterMap,
//     warehouse: warehouseRouterMap,
//     chef: chefRouterMap,
//     counter: counterRouterMap,
//     handlers: handlersRouterMap,
//     customer: customerRouterMap
//   }
// }

router.beforeEach((to, from, next) => {
  NProgress.start()
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${defaultConfig.title}`))

  // 登录地址直接进入
  if (loginList.includes(to.name)) {
    next()
  } else {
    if (storage.get(ACCESS_TOKEN)) {
      if (store.state.user.menus.length === 0) {
        store.dispatch('GetInfo').then((res) => {
          if (res.storeId) {
            store.dispatch('GetStoreInfo', res.storeId).then()
          }
          const types = res.roleTypes
          const userAuth = roleNames[types[0]]

          const port = store.state.user.port || storage.get('PORT')

          console.log(userAuth, port)
          if (userAuth !== port) {
            store.commit('SET_PORT', userAuth)
            next({ name: userAuth })
          }

          store.dispatch('GetMenus').then(menus => {
            let aaa = []

            if (roleNames.includes(port)) {
              aaa = roleRouters[port]
            }

            store.dispatch('GenerateRoutes', { menus, aaa }).then(routes => {
              routes.map(e => {
                router.addRoute(e)
              })
              next({ ...to, replace: true })
            })
          })
        })
      } else {
        next()
      }
    } else {
      if (whiteList.includes(to.name)) {
        next()
      } else {
        for (let i = 0; i < roleNames.length; i++) {
          if (checkPath(to.path, roleNames[i])) {
            next({ name: roleNames[i] + '-login' })
            break
          }
        }
        next()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
