import storage from 'store'
import { ACCESS_TOKEN, ACCESS_TOKEN_SIMPLE } from '@/store/mutation-types'
import { dataToPromiseTree } from '@/utils'
import { UserClass } from '@/apis/user'
import { StoreClass } from '@/apis/store'
import { WxGZHCLass } from '@/apis/wxgzh'
import DefaultConfig from '@/config/default.config'

const api = new UserClass()
const store = new StoreClass()
const wxgzh = new WxGZHCLass()
const user = {
  state: {
    token: '',
    token_simple: '',
    menus: [],
    allMenus: [],
    roles: [],
    allRoles: [],
    userInfo: {},
    projectId: 0,
    port: '',

    storeInfo: {},

    SCAN_PEOPLE_COUNT: 0,
    SCAN_TABLE_ID: 0,
    SCAN_STORE_ID: ''
  },
  mutations: {
    SET_TOKEN (state, value) {
      state.token = value
    },

    SET_TOKEN_SIMPLE (state, value) {
      state.token_simple = value
    },

    SET_MENUS (state, value) {
      state.menus = value
    },

    SET_USER_MENUS (state, value) {
      state.allMenus = value
    },

    SET_ROLE (state, value) {
      state.roles = value
    },

    SET_INFO (state, value) {
      state.userInfo = value
    },

    SET_STORE_INFO (state, value) {
      state.storeInfo = value
    },

    SET_PROJECTID (state, value) {
      state.projectId = value
    },

    SET_PORT (state, value) {
      state.port = value
      storage.set('PORT', value)
    },

    SET_SCAN_TABLE_ID (state, value) {
      state.SCAN_TABLE_ID = value
      storage.set('SCAN_TABLE_ID', value)
    },

    SET_SCAN_STORE_ID (state, value) {
      state.SCAN_STORE_ID = value
      storage.set('SCAN_STORE_ID', value)
    },

    SET_SCAN_PEOPLE_COUNT (state, value) {
      state.SCAN_PEOPLE_COUNT = value
      storage.set('SCAN_PEOPLE_COUNT', value)
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        api.login(userInfo).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          commit('SET_PORT', 'manage')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 酒店登录
    SLogin ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        api.slogin(userInfo).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 客户登录
    CLogin ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        api.clogin(userInfo).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          commit('SET_PORT', 'customer')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 权限菜单
    GetMenus ({ commit }) {
      return new Promise((resolve, reject) => {
        api.menus().then(response => {
          if (response.length === 0) {
            response = [
              {
                name: '首页',
                sign: 'index',
                parentId: 0
              }
            ]
          }
          const pros = dataToPromiseTree(response, 0)

          commit('SET_MENUS', pros)

          resolve(pros)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        api.userInfo().then(response => {
          commit('SET_ROLE', response.roleIds)
          commit('SET_INFO', response)
          commit('SET_PROJECTID', response.projectId)
          resolve(response)
        }).catch(() => {
          reject(new Error('获取用户信息失败 !'))
        })
      })
    },

    // 获取酒店信息
    GetStoreInfo ({ commit }, id) {
      return new Promise((resolve, reject) => {
        store.detail(id).then(resp => {
          commit('SET_STORE_INFO', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取酒店信息失败 !'))
        })
      })
    },

    // 登出
    Logout ({ commit }) {
      commit('SET_MENUS', [])
      return new Promise((resolve) => {
        api.logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_TOKEN_SIMPLE', '')
          commit('SET_ROLE', [])
          storage.remove(ACCESS_TOKEN)
          storage.remove(ACCESS_TOKEN_SIMPLE)
          resolve()
        })
      })
    },

    SnsapiBase () {
      const appid = DefaultConfig.app_id
      const redirect = DefaultConfig.host + '/customer/login'
      const scope = 'snsapi_base'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    SnsapiUserinfo () {
      const appid = DefaultConfig.app_id
      const redirect = DefaultConfig.host + '/customer/regist'
      const scope = 'snsapi_userinfo'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    SnsapiBase2 () {
      const appid = DefaultConfig.app_id
      const redirect = DefaultConfig.host + '/scan/login'
      const scope = 'snsapi_base'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    SnsapiUserinfo2 () {
      const appid = DefaultConfig.app_id
      const redirect = DefaultConfig.host + '/scan/regist'
      const scope = 'snsapi_userinfo'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    SnsapiBase3 () {
      const appid = DefaultConfig.app_id
      const redirect = DefaultConfig.host + '/waimai/login'
      const scope = 'snsapi_base'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    SnsapiUserinfo3 () {
      const appid = DefaultConfig.app_id
      const redirect = DefaultConfig.host + '/waimai/regist'
      const scope = 'snsapi_userinfo'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    WxGZHLogin ({ commit }, code) {
      return new Promise((resolve, reject) => {
        wxgzh.login(code).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          commit('SET_PORT', 'customer')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    WxGZHRegist ({ commit }, code) {
      return new Promise((resolve, reject) => {
        wxgzh.regist(code).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          commit('SET_PORT', 'customer')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetScanTableId () {
      return new Promise((resolve, reject) => {
        resolve(storage.get('SCAN_TABLE_ID'))
      })
    },

    GetScanPeopleCount () {
      return new Promise((resolve, reject) => {
        resolve(storage.get('SCAN_PEOPLE_COUNT'))
      })
    },

    GetScanStoreId () {
      return new Promise((resolve, reject) => {
        resolve(storage.get('SCAN_STORE_ID'))
      })
    }
  }
}

export default user
