import { BaseClass } from './base'

export class WxGZHCLass extends BaseClass {
  login (code) {
    return this.get(this.wxgzh + '/Login', { code })
  }

  regist (code) {
    return this.get(this.wxgzh + '/Register', { code })
  }

  jsConfig (url = '') {
    if (url === '') {
      url = location.href
    }
    return this.get(this.wxgzh + '/GetWxJSConfig', { url })
  }

  pay (orderId) {
    return this.post(this.wxpay + '/TYPrepayGZH', { orderId })
  }
}
